export const superviseStateVO = function () {
  return {
    supervise: {},
    receiveType: '', //接收类型
    receiveId: '', //接受对象id
    ifRead: '', //是否已读
    ifReply: '', //是否回复
    content: '', //回复内容
    readTime: '', //阅读时间
    replyTime: '', //回复时间
    replyPerson: {}, //回复人
    ifOvertime: '', //是否超时接受
    state: 0,
    memo: ''
  }
}