<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">我的督办信息列表</h1>
    </div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item label="标题">
            <a-input v-model="searchParams.title" @change="search" placeholder="请输入标题"/>
          </a-form-item>
          <a-form-item label="发布人">
            <a-select
              allowClear showSearch optionFilterProp="title"
              v-model="searchParams.publisher"
              placeholder="请选择发布人"
              @change="search">
              <a-select-option :key="item.id"
                               :value="item.id"
                               :title="item.name"
                               v-for="item in usersData">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" @click="search" icon="search">查询</a-button>
            <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table bordered size="default"
               :rowKey="record => record.id"
               :columns="columns"
               @change="tableChange"
               :pagination="pagination"
               :dataSource="tableData"
               :loading="tableLoading">
        <template slot="_index" slot-scope="text, record, index">{{index+1}}</template>
        <template slot="receiveType" slot-scope="text, record">
          <a-tag v-if="record.receiveType==='all'">全部</a-tag>
          <a-tag v-if="record.receiveType==='dept'" color="green">部门</a-tag>
          <a-tag v-if="record.receiveType==='user'" color="red">用户</a-tag>
        </template>
        <template slot="ifRead" slot-scope="text, record">
          <a-tag v-if="record.ifRead" color="green">已读</a-tag>
          <a-tag v-else color="red">未读</a-tag>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button @click="handleView(record.id)" shape="circle" icon="menu" size="small"></a-button>
        </template>
      </a-table>
      <a-drawer
        title="督办信息详情"
        width="600"
        placement="right"
        :closable="true"
        @close="detailClose"
        :visible="detailDrawer">
        <supervise-state-detail ref="viewRef"></supervise-state-detail>
      </a-drawer>
    </a-card>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import entityCRUD from '@/views/common/mixins/entityCRUD'
  import SERVICE_URLS from '../../../api/service.url'
  import { superviseStateColumns } from './common/common'
  import SuperviseStateDetail from './StateDetail'

  export default {
    name: 'superviseStateList',
    mixins: [entityCRUD],
    components: { SuperviseStateDetail, PageLayout },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.platBase.superviseState,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          receiveId: '', //接收人ID
          title: '',
          publisher: null
        },
        initColumns: superviseStateColumns(),
        usersData: [] //用户下拉数据
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    created () {
      this.searchParams.receiveId = this.currentUser.id
      this.search()
      this.loadUsersData()
    },
    methods: {
      //是否已读点击查看详情
      readDetailClick (id) {
        console.info('?????????', id)
      },
      loadUsersData () {
        this.$http(this, {
          url: SERVICE_URLS.user.userOrgJob.orgUser,
          noTips: true,
          success: (data) => {
            this.usersData = data.body
          }
        })
      }
    }
  }
</script>